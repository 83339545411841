import { useEffect } from "react";

const loadHubspot = (event) => {
  if (window.hbspt) {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "23689769",
      formId: "31279380-de1c-4a9b-af7c-252369a75675",
      target: "#lead",
      onFormSubmitted: submitLead
    });
  }
}

const submitLead = (event) => {
  console.log(event);
}

export const FreeEstimate = (props) => {

  useEffect(() => {
    if (!document.hubspot_loaded) {
      document.hubspot_loaded = true;
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.onload = loadHubspot;
      document.body.appendChild(script);
    }
  }, [])

  return (
    <div id='free-estimate'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Free Estimate</h2>
          <p>Submit the form below and you'll be able to get in touch with one of our tax experts</p>
        </div>
        <div className='row lead-form-container'>
          <div id="lead" name="lead" className="lead-form col-lg-4 col-md-6 col-sm-12 col-xs-12"></div> 
        </div>
      </div>
    </div>
  )
}
