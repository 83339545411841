export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <p className='container text-center'>
          Discount Tax Services, Inc<br/>
          3320 W 63rd St<br/>
          Chicago, IL 60629<br/>
        </p>
      </div>
    </div>
  )
}
