import { useLoaderData } from "react-router-dom";

export const Success = (props) => {
  const { text } = useLoaderData();

  return (
    <div id='success'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>{text.Success ? text.Success.title : 'loading'}</h2>
          <p>{text.Success ? text.Success.description1 : 'loading'}</p>
          <p>{text.Success ? text.Success.description2 : 'loading'}</p>
          <br/>
          <div className="fb-page" data-href="https://www.facebook.com/Discount.Tax.Services.Illinois" data-tabs="events" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Discount.Tax.Services.Illinois" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Discount.Tax.Services.Illinois">Discount Tax Services</a></blockquote></div>
        </div>
      </div>
    </div>
  )
}
