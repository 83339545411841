import { Outlet } from "react-router-dom";
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'
import SmoothScroll from 'smooth-scroll'
import JsonData from '../data/data.json'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

export async function loader() {
  return { text: JsonData }
}

export const Root = (props) => {
  return (
    <div>
      <Navigation />
      <Outlet />
      <Footer />
    </div>
  )
}
