import { Header } from '../components/header'
import { Services } from '../components/services'
import { About } from '../components/about'
import { Testimonials } from '../components/testimonials'
import { FreeEstimate } from '../components/free-estimate'

export const Home = () => {
  return (
    <div>
      <Header />
      <Services />
      <About />
      <Testimonials/>
      <FreeEstimate />
    </div>
  )
}
