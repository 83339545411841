import React from 'react'
import { createRoot } from 'react-dom/client'
import { Root, loader as rootLoader } from './routes/root'
import { Home } from './pages/home'
import { Success } from './pages/success'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import './index.css'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        path: "/",
        loader: rootLoader,
        element: <Home />,
      },
      {
        path: "/success",
        loader: rootLoader,
        element: <Success />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
