import { useLoaderData } from "react-router-dom";

export const Header = () => {
  const { text } = useLoaderData();
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className="title-text-container col-md-12">
                <b style={{backgroundColor:'white'}}>Start</b> the year off <b style={{backgroundColor:'white'}}>right</b> and get a head start on your tax returns <b style={{backgroundColor:'white'}}>now</b>
              </div>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <p>{text.Header ? text.Header.cta1 : 'Loading'}</p>
                <p>{text.Header ? text.Header.cta2 : 'Loading'} <b style={{backgroundColor:'white', color: 'black'}}>FREE ESTIMATE</b></p>
                <div className="arrow bounce">
                  <a className="fa fa-arrow-down fa-4x" href="#" value=""/>
                </div>
                <a
                  href='#free-estimate'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Don't Delay, File <span style={{'color': '#7BEA26'}}>Today!</span>
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
